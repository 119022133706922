#ageModal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 20px;
    width: 300px;
    margin: 20% auto;
    text-align: center;
    border-radius: 10px;
}

.modal-buttons button {
    margin: 10px;
    padding: 10px;
    cursor: pointer;
}